import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Grip Deadlifts 5×2\\@90% deadlift max`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`20 SDHP’s (75/55)`}</p>
    <p>{`20 Burpees Over Bar`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`The signup sheet for Memorial Day Murph is up next to the white
board at the gym.  We’ll have heats starting every :30 beginning at
9:00am.  Most are full until the later heats so if you haven’t signed up
you may want to arrive around 11:00.  **`}</strong>{`We’ll have a potluck/cookout
afterward, around 12:00.  Last names beginning in A-M please bring a
side dish, N-Z a dessert.  Thanks to a generous donation from our member
CJ and Seven Seas Construction all of the meat for grilling will be
provided as well!***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Wednesday at our 10:30am class we’ll have a video shoot of our
new outdoor workout area!  If you’d like to help out by being part of
the video & class please let us know.  We’d love to have a big
turnout.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      